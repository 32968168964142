import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import "./ButtonMenu.scss";
import {
  updateComponentStyle,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { ButtonComponent } from "../../../../_default/interfaces/base";

export default function ButtonMenu({ id, triggerResize }) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as ButtonComponent & any;

  const [selectedWidth, setSelectedWidth] = useState<string | undefined>(
    component.attributes.styles.width
      ? component.attributes.styles.width.includes("px")
        ? "px"
        : "%"
      : "px"
  );

  const [selectedHeight, setSelectedHeight] = useState<string | undefined>(
    component.attributes.styles.height &&
      component.attributes.styles.height !== "none"
      ? component.attributes.styles.height.includes("px")
        ? "px"
        : "%"
      : "px"
  );

  const [selectedMaxWidth, setSelectedMaxWidth] = useState<string | undefined>(
    component.attributes.styles.maxWidth &&
      component.attributes.styles.maxWidth !== "none"
      ? component.attributes.styles.maxWidth.includes("px")
        ? "px"
        : "%"
      : "px"
  );

  const [selectedMaxHeight, setSelectedMaxHeight] = useState<
    string | undefined
  >(
    component.attributes.styles.maxHeight &&
      component.attributes.styles.maxHeight !== "none"
      ? component.attributes.styles.maxHeight.includes("px")
        ? "px"
        : "%"
      : "px"
  );

  const [selectedMinWidth, setSelectedMinWidth] = useState<string | undefined>(
    component.attributes.styles.minWidth &&
      component.attributes.styles.minWidth !== "none"
      ? component.attributes.styles.minWidth.includes("px")
        ? "px"
        : "%"
      : "px"
  );

  const [selectedMinHeight, setSelectedMinHeight] = useState<
    string | undefined
  >(
    component.attributes.styles.minHeight &&
      component.attributes.styles.minHeight !== "none"
      ? component.attributes.styles.minHeight.includes("px")
        ? "px"
        : "%"
      : "px"
  );

  const wrapTypes = [
    {
      value: "inline-grid",
      label: "Wrap",
    },

    {
      value: "flex",
      label: "No wrap",
    },
  ];

  let widthTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "%",
      label: "%",
    },
  ];

  let heightTypes = [
    {
      value: "px",
      label: "px",
    },
  ];

  const minWidthTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "%",
      label: "%",
    },
  ];

  const minHeightTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "%",
      label: "%",
    },
  ];

  const maxWidthTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "%",
      label: "%",
    },
  ];

  const maxHeightTypes = [
    {
      value: "px",
      label: "px",
    },
    {
      value: "%",
      label: "%",
    },
  ];

  useEffect(() => {
    triggerResize();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {component.type === "Button" && (
          <>
            <span>Wrap:</span>

            <Select
              menuPosition="fixed"
              className="m-0 button-menu_input"
              isSearchable={false}
              styles={TextEditorSelectStyle}
              noOptionsMessage={({}) => ""}
              value={
                component.attributes
                  ? wrapTypes.find(
                      (x) => x.value === component.attributes.styles.display
                    )
                  : wrapTypes[0]
              }
              onChange={(val) => {
                if (val.value === "flex") {
                  updateComponentStyle(id, {
                    display: val.value,
                    alignItems: "center",
                    justifyContent: "center",
                  });
                } else {
                  updateComponentStyle(id, {
                    display: val.value,
                  });
                }
              }}
              options={wrapTypes}
              placeholder=""
            ></Select>
          </>
        )}
        <span>Width:</span>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            className="global-editor__input builder-input spacing-menu_input"
            size={1}
            value={
              component.attributes.styles.width
                ? component.attributes.styles.width.replace(
                    `${selectedWidth}`,
                    ""
                  )
                : ""
            }
            onChange={(ev) => {
              // if (ev.target.value === "" || ev.target.value === "0") {
              //   setSelectedWidth("px");
              //   return;
              // }

              if (ev.target.value === "0") return;

              updateComponentStyle(id, {
                width: ev.target.value + selectedWidth,
              });
            }}
          />

          <Select
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={widthTypes.find((x) => x.value === selectedWidth)}
            onChange={(val) => {
              setSelectedWidth(val.value);

              updateComponentStyle(id, {
                width: component.attributes.styles.width
                  ? component.attributes.styles.width.replace(
                      `${selectedWidth}`,
                      val.value
                    )
                  : "",
              });
            }}
            options={widthTypes}
            placeholder=""
          ></Select>
        </div>

        <span>Height:</span>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            disabled={
              component.attributes.maintainAspectRatio === true ? true : false
            }
            className="global-editor__input builder-input spacing-menu_input "
            size={1}
            value={
              component.attributes.styles.height
                ? component.attributes.styles.height.replace(
                    `${selectedHeight}`,
                    ""
                  )
                : ""
            }
            onChange={(ev) => {
              if (ev.target.value === "0") return;

              updateComponentStyle(id, {
                height: ev.target.value + selectedHeight,
              });
            }}
          />

          <Select
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={heightTypes.find((x) => x.value === selectedHeight)}
            onChange={(val) => {
              setSelectedHeight(val.value);

              updateComponentStyle(id, {
                height: component.attributes.styles.height
                  ? component.attributes.styles.height.replace(
                      `${selectedHeight}`,
                      val.value
                    )
                  : "",
              });
            }}
            options={heightTypes}
            placeholder=""
          ></Select>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          marginTop: "5px",
        }}
      >
        <span>Max-Width: </span>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            className="global-editor__input builder-input spacing-menu_input"
            size={1}
            value={
              component.attributes.styles.maxWidth === "none"
                ? ""
                : component.attributes.styles.maxWidth
                ? component.attributes.styles.maxWidth.replace(
                    `${selectedMaxWidth}`,
                    ""
                  )
                : ""
            }
            onChange={(ev) => {
              if (
                !ev.target.value ||
                ev.target.value == "none" ||
                ev.target.value == ""
              ) {
                updateComponentStyle(id, {
                  maxWidth: "none",
                });
              } else if (ev.target.value === "0") {
                updateComponentStyle(id, {
                  maxWidth: "none",
                });
              } else {
                updateComponentStyle(id, {
                  maxWidth: ev.target.value + selectedMaxWidth,
                });
              }
            }}
          />

          <Select
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={maxWidthTypes.find((x) => x.value === selectedMaxWidth)}
            onChange={(val) => {
              setSelectedMaxWidth(val.value);

              updateComponentStyle(id, {
                maxWidth: component.attributes.styles.maxWidth
                  ? component.attributes.styles.maxWidth.replace(
                      `${selectedMaxWidth}`,
                      val.value
                    )
                  : "",
              });
            }}
            options={maxWidthTypes}
            placeholder=""
          ></Select>
        </div>

        <span>Max-Height: </span>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            className="global-editor__input builder-input spacing-menu_input"
            size={1}
            value={
              component.attributes.styles.maxHeight === "none"
                ? ""
                : component.attributes.styles.maxHeight
                ? component.attributes.styles.maxHeight.replace(
                    `${selectedMaxHeight}`,
                    ""
                  )
                : ""
            }
            onChange={(ev) => {
              if (
                !ev.target.value ||
                ev.target.value == "none" ||
                ev.target.value == ""
              ) {
                updateComponentStyle(id, {
                  maxHeight: "none",
                });
              } else if (ev.target.value === "0") {
                updateComponentStyle(id, {
                  maxHeight: "none",
                });
              } else {
                updateComponentStyle(id, {
                  maxHeight: ev.target.value + selectedMaxHeight,
                });
              }
            }}
          />

          <Select
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={maxHeightTypes.find((x) => x.value === selectedMaxHeight)}
            onChange={(val) => {
              setSelectedMaxHeight(val.value);

              updateComponentStyle(id, {
                maxHeight: component.attributes.styles.maxHeight
                  ? component.attributes.styles.maxHeight.replace(
                      `${selectedMaxHeight}`,
                      val.value
                    )
                  : "",
              });
            }}
            options={maxHeightTypes}
            placeholder=""
          ></Select>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          marginTop: "5px",
        }}
      >
        <span>Min-Width: </span>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            className="global-editor__input builder-input spacing-menu_input"
            size={1}
            value={
              component.attributes.styles.minWidth === "none"
                ? ""
                : component.attributes.styles.minWidth
                ? component.attributes.styles.minWidth.replace(
                    `${selectedMinWidth}`,
                    ""
                  )
                : ""
            }
            onChange={(ev) => {
              if (
                !ev.target.value ||
                ev.target.value == "none" ||
                ev.target.value == ""
              ) {
                updateComponentStyle(id, {
                  minWidth: "none",
                });
              } else if (ev.target.value === "0") {
                updateComponentStyle(id, {
                  minWidth: "none",
                });
              } else {
                updateComponentStyle(id, {
                  minWidth: ev.target.value + selectedMinWidth,
                });
              }
            }}
          />

          <Select
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={minWidthTypes.find((x) => x.value === selectedMinWidth)}
            onChange={(val) => {
              setSelectedMinWidth(val.value);

              updateComponentStyle(id, {
                minWidth: component.attributes.styles.minWidth
                  ? component.attributes.styles.minWidth.replace(
                      `${selectedMinWidth}`,
                      val.value
                    )
                  : "",
              });
            }}
            options={minWidthTypes}
            placeholder=""
          ></Select>
        </div>

        <span>Min-Height: </span>

        <div style={{ display: "flex", gap: "5px" }}>
          <input
            className="global-editor__input builder-input spacing-menu_input"
            size={1}
            value={
              component.attributes.styles.minHeight === "none"
                ? ""
                : component.attributes.styles.minHeight
                ? component.attributes.styles.minHeight.replace(
                    `${selectedMinHeight}`,
                    ""
                  )
                : ""
            }
            onChange={(ev) => {
              if (
                !ev.target.value ||
                ev.target.value == "none" ||
                ev.target.value == ""
              ) {
                updateComponentStyle(id, {
                  minHeight: "none",
                });
              } else if (ev.target.value === "0") {
                updateComponentStyle(id, {
                  minHeight: "none",
                });
              } else {
                updateComponentStyle(id, {
                  minHeight: ev.target.value + selectedMinHeight,
                });
              }
            }}
          />

          <Select
            className="m-0 button-menu_input"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={minHeightTypes.find((x) => x.value === selectedMinHeight)}
            onChange={(val) => {
              setSelectedMinHeight(val.value);

              updateComponentStyle(id, {
                minHeight: component.attributes.styles.minHeight
                  ? component.attributes.styles.minHeight.replace(
                      `${selectedMinHeight}`,
                      val.value
                    )
                  : "",
              });
            }}
            options={minHeightTypes}
            placeholder=""
          ></Select>
        </div>
      </div>

      {component.type === "image" && (
        <>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <span>Maintain aspect ratio:</span>

            <label className="switch" style={{ marginRight: "5px" }}>
              <input
                type="checkbox"
                checked={
                  component.attributes.hasOwnProperty("maintainAspectRatio")
                    ? component.attributes.maintainAspectRatio
                    : false
                }
                onChange={(e) => {
                  updateStructureComponent(
                    id,
                    "attributes.maintainAspectRatio",
                    e.target.checked
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </>
      )}
    </div>
  );
}
