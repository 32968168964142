import { AllComponents } from "./interfaces/base";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import BackgroundVideoComponent from "./BackgroundVideoComponent";
import useLazyLoad from "./utilities/useLazyLoad";
import React from "react";
interface Props {
  id: number;
  attributes: AllComponents["attributes"] & any; //Fix this
  childElements: AllComponents[];
  childWebpageComponentRelations: any;
  editor: JSX.Element;
}

const InnerSection = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function InnerSectionComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
  editor,
}: Props) {
  const sectionRefElement = useRef<HTMLDivElement>(null);

  const [backgroundImage, setBackgroundImage] = useState(
    attributes.styles.backgroundImage ? attributes.styles.backgroundImage : ""
  );
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();

  const colors = useAppSelector((state) => state.builder.colors);
  const [borderColors, setBorderColors] = useState<any>();
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;

  useEffect(() => {
    if (attributes.styles.backgroundImage) {
      setBackgroundImage(attributes.styles.backgroundImage);
    }
  }, [attributes.styles.backgroundImage]);

  useEffect(() => {
    const backgroundColor = component.attributes?.color_id
      ? colors?.find((x: any) => x.id === component.attributes?.color_id)?.color
      : component.attributes?.styles.backgroundColor
      ? component.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = component.attributes?.hover_color_id
      ? colors?.find((x: any) => x.id === component.attributes?.hover_color_id)
          ?.color
      : component.attributes?.styles[":hover"] &&
        component.attributes?.styles[":hover"].backgroundColor
      ? component.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "none"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    component.attributes,
    component.attributes?.color_id,
    component.attributes?.hover_color_id,
    component.attributes?.styles.backgroundColor,
    component.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  useEffect(() => {
    // Change border color values

    const borderColorValues = {
      borderTopColor: component.attributes?.borderTopColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderTopColorId
          )?.color
        : component.attributes?.styles.borderTopColor
        ? component.attributes?.styles.borderTopColor
        : "",
      borderBottomColor: component.attributes?.borderBottomColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderBottomColorId
          )?.color
        : component.attributes?.styles.borderBottomColor
        ? component.attributes?.styles.borderBottomColor
        : "",
      borderLeftColor: component.attributes?.borderLeftColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderLeftColorId
          )?.color
        : component.attributes?.styles.borderLeftColor
        ? component.attributes?.styles.borderLeftColor
        : "",
      borderRightColor: component.attributes?.borderRightColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderRightColorId
          )?.color
        : component.attributes?.styles.borderRightColor,
    };

    setBorderColors(borderColorValues);


  }, [
    component.attributes,
    component.attributes.styles,
    component.attributes?.borderBottomColorId,
    component.attributes?.borderLeftColorId,
    component.attributes?.borderRightColorId,
    component.attributes?.borderTopColorId,
    colors,
  ]);

  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  if (attributes && attributes.classes == "inner-section") {
    let style: any = {};
    if (attributes.content_width_size) {
      style = {
        maxWidth: attributes.content_width_size + attributes.content_width_unit,
      };
    }

    return (
      <div className="container g-0" style={style} ref={sectionRefElement}>
        <InnerSection
          id={id ? id.toString() : ""}
          data-style={{
            ...attributes.styles,
            ...backgroundStyles,
            borderLeftColor: borderColors?.borderLeftColor,
            borderRightColor: borderColors?.borderRightColor,
            borderTopColor: borderColors?.borderTopColor,
            borderBottomColor: borderColors?.borderBottomColor,
          }}
          className={
            attributes &&
            attributes.classes +
              " g-0 builder-component relative builder-component__section" +
              (editorId === id ? " builder-component--active" : "")
          }
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
          }}
          data-component-type="inner-section"
        >
          <BackgroundVideoComponent id={id} />
          {attributes && attributes.text}
          <RenderChildren
            key={id + "children"}
            childWebpageComponentRelations={childWebpageComponentRelations}
          />
        </InnerSection>
      </div>
    );
  }
  if (attributes && attributes.classes == "container-fluid") {
    let style: any = {};
    if (attributes.content_width_size) {
      style = {
        maxWidth: attributes.content_width_size + attributes.content_width_unit,
      };
    } else {
      style = {
        maxWidth: "100%",
      };
    }

    return (
      <InnerSection
        ref={sectionRefElement}
        id={id ? id.toString() : ""}
        data-style={{
          ...attributes.styles,
          ...backgroundStyles,
        }}
        className={
          attributes &&
          attributes.classes +
            " g-0 builder-component relative builder-component__section" +
            (editorId === id ? " builder-component--active" : "")
        }
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
        data-component-type="inner_section"
      >
        <BackgroundVideoComponent id={id} />
        {attributes && attributes.text}
        <div className="container" style={style}>
          <RenderChildren
            key={id + "children"}
            childWebpageComponentRelations={childWebpageComponentRelations}
          />
        </div>
      </InnerSection>
    );
  }

  return (
    <InnerSection
      ref={sectionRefElement}
      id={id ? id.toString() : ""}
      data-style={{
        ...attributes.styles,
        ...backgroundStyles,
      }}
      className={
        attributes &&
        attributes.classes +
          " g-0 builder-component relative builder-component__section" +
          (editorId === id ? " builder-component--active" : "")
      }
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
      data-component-type="inner_section"
    >
      <BackgroundVideoComponent id={id} />
      {attributes && attributes.text}
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
    </InnerSection>
  );
}
