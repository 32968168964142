import { useEffect } from "react";
import { FormComponent as FormComponent_ } from "./interfaces/base";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
import "./Form.css";
import BackgroundVideoComponent from "./BackgroundVideoComponent";
import { useAppSelector } from "../../store/hooks";
import React from "react";

const Form = styled("form")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

interface Props {
  id: number;
  attributes: FormComponent_["attributes"];
  editor: JSX.Element;
  childElements: any;
  childWebpageComponentRelations: any;
}

declare var API: any;

export default function FormComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: Props) {
  const activePage = useAppSelector((state) => state.builder.pages.active);
  const hash = Date.now().toString();

  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    const parentEl = document.getElementById(id.toString());
    const hashElement =
      parentEl?.querySelector<HTMLInputElement>("[name=hash]");
    const formData = new FormData(parentEl as HTMLFormElement);

    if (hashElement && hashElement.value === hash) {
      window
        // @ts-ignore
        .axios("/submitContactForm", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: formData,
        })
        .then((response: any) => {
          if (response.status === 200) {
            document.getElementById("form-success-message")!.style.display =
              "block";
          } else {
            console.log("response", response);
            alert(response.statusText);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  };

  setTimeout(() => {
    const parentEl = document.getElementById(id.toString());

    const hashElement =
      parentEl?.querySelector<HTMLInputElement>("[name=hash]");

    if (hashElement) {
      hashElement.value = hash;
    } else {
    }
  }, 7000);

  const getElementAsync = (className) => {
    return new Promise((resolve) => {
      const getElement = () => {
        const elements = document.querySelectorAll(`form .${className}`);

        if (elements.length > 0) {
          resolve(elements);
        } else {
          requestAnimationFrame(getElement);
        }
      };

      getElement();
    });
  };

  const componentDidMount = async () => {
    // Find the button element, that has type=="submit"
    const buttonElements = (await getElementAsync(
      "builder-component__button"
    )) as NodeListOf<HTMLButtonElement>;

    (buttonElements as NodeListOf<HTMLButtonElement>).forEach((button) => {
      // Check if button.type === 'submit', then add eventListener
      if (button.type === "submit") {
        button.addEventListener("click", handleSubmit);
      }
    });

    return () => {
      (buttonElements as NodeListOf<HTMLButtonElement>).forEach((button) => {
        // Check if button.type === 'submit', then add eventListener
        if (button.type === "submit") {
          button.removeEventListener("click", handleSubmit);
        }
      });
    };
  };

  useEffect(() => {
    componentDidMount();
  }, [activePage]);

  return (
    <>
      <Form
        id={id ? id.toString() : ""}
        onSubmit={handleSubmit}
        action={attributes.action}
        data-style={attributes && attributes.styles}
        className={attributes && attributes.classes + " relative"}
      >
        <BackgroundVideoComponent id={id} />
        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations}
        />
      </Form>
      <div
        style={{
          color: "green",
          fontSize: "14px",
          marginTop: "5px",
          display: "none",
        }}
        id="form-success-message"
      >
        Sõnum saadetud.
      </div>
    </>
  );
}
