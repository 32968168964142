import { MoreHorizOutlined } from "@mui/icons-material";
import React, { CSSProperties, useEffect, useState } from "react";
import Select from "react-select";
import store from "../../../../../store/store";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";
import {
  updateComponentStyle,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import Dropdown from "../../../Dropdown/Dropdown";
import { useAppSelector } from "../../../../../store/hooks";
import { idText } from "typescript";

export default function BorderMenu({
  id,
  triggerResize,
}: {
  id: number;
  triggerResize: () => void;
}) {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;
  const [expanded, setExpanded] = useState(false);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [openedColorPicker, setOpenedColorPicker] = useState("");

  const [borders, setBorders] = useState<any>({});
  const [tempBorderValues, setTempBorderValues] = useState({});

  const borderStyles = [
    { label: "Solid", value: "solid" },
    { label: "Dashed", value: "dashed" },
    { label: "Dotted", value: "dotted" },
    { label: "Double", value: "double" },
    { label: "Groove", value: "groove" },
    { label: "Ridge", value: "ridge" },
    { label: "Outset", value: "outset" },
    { label: "None", value: "none" },
  ];

  const widthUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
  ];
  const radiusUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
    { value: "%", label: "%" },
  ];

  const borderProperties = [
    { value: "width", defaultValue: "0px" },
    { value: "radius", defaultValue: "0px" },
    { value: "style", defaultValue: "solid" },
    { value: "color", defaultValue: "#fff" },
  ];

  const borderPositions = ["top", "bottom", "left", "right"];
  const borderRadiusPositions = [
    "topLeft",
    "bottomLeft",
    "topRight",
    "bottomRight",
  ];

  const borderPosRadiusMap: any = {
    top: borderRadiusPositions[0],
    bottom: borderRadiusPositions[1],
    left: borderRadiusPositions[2],
    right: borderRadiusPositions[3],
  };

  useEffect(() => {
    let borders_: any = {};
    borderProperties.forEach((prop) => {
      if (prop.value === "radius") {
        borderRadiusPositions.forEach((pos) => {
          let attr =
            "border" + capFirstLetter(pos) + capFirstLetter(prop.value);
          borders_[attr] =
            component.attributes.styles[attr] ?? prop.defaultValue;
        });
      } else {
        borderPositions.forEach((pos) => {
          let attr =
            "border" + capFirstLetter(pos) + capFirstLetter(prop.value);
          borders_[attr] =
            component.attributes.styles[attr] ?? prop.defaultValue;
        });
      }
    });

    setBorders(borders_);
  }, []);

  function extractNumbers(str: string | undefined) {
    widthUnits.forEach((w) => {
      str = str?.replace(w.value, "");
    });
    radiusUnits.forEach((w) => {
      str = str?.replace(w.value, "");
    });
    return parseInt(str! ?? 0);
  }

  function extractUnits(str: string | undefined) {
    let foundUnit = "px";
    widthUnits.forEach((w) => {
      if (str?.includes(w.value)) foundUnit = w.value;
    });
    radiusUnits.forEach((w) => {
      if (str?.includes(w.value)) foundUnit = w.value;
    });
    return foundUnit;
  }

  function capFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    // Update the useState values of cachedBorderRadiusValues
    // );
  }, [borders]);

  function setValueByProp(
    pos: string,
    prop: string,
    value: any,
    colorId: null | number = null
  ) {
    let copy: any = { ...borders };
    copy["border" + capFirstLetter(pos) + prop] = value;

    updateStructureComponent(
      id,
      `attributes.border${capFirstLetter(pos)}ColorId`,
      colorId ? colorId : null
    );

    if (!expanded) {
      borderPositions.forEach((pos_) => {
        updateStructureComponent(
          id,
          `attributes.border${capFirstLetter(pos_)}ColorId`,
          colorId ? colorId : null
        );

        copy[
          "border" +
            capFirstLetter(
              prop == "Radius" ? capFirstLetter(borderPosRadiusMap[pos_]) : pos_
            ) +
            prop
        ] = value;
      });
    }

    setBorders(copy);
    updateComponentStyle(id, copy);
  }

  useEffect(() => {
    triggerResize();
  }, []);

  function renderRow(position: string) {
    return (
      <>
        <div className="text-start d-flex" style={{ alignItems: "center" }}>
          Border {expanded ? position : ""}
        </div>

        <div className="d-flex border-menu_input ">
          <div
            style={{
              fontSize: "14px",
            }}
            onClick={() => {
              setValueByProp(
                position,
                "Width",
                extractNumbers(
                  borders["border" + capFirstLetter(position) + "Width"]
                ) -
                  1 +
                  extractUnits(
                    borders["border" + capFirstLetter(position) + "Width"]
                  )
              );
            }}
          >
            −
          </div>
          <div>
            <input
              className="global-editor__input builder-input"
              type="number"
              style={{
                width: "25px",
              }}
              size={1}
              value={
                tempBorderValues[`${position}Width`] !== undefined
                  ? tempBorderValues[`${position}Width`]
                  : extractNumbers(
                      borders["border" + capFirstLetter(position) + "Width"]
                    )
              }
              onBlur={() => {
                // Clear temporary state on blur to reset the value
                setTempBorderValues((prev) => {
                  const updatedValues = { ...prev };
                  delete updatedValues[`${position}Width`];
                  return updatedValues;
                });
              }}
              onChange={(ev) => {
                const newValue = ev.target.value;

                // Update the temporary state for this specific input
                setTempBorderValues((prev) => ({
                  ...prev,
                  [`${position}Width`]: newValue,
                }));

                // Only update the main state when there is a valid number
                if (newValue === "") {
                  setValueByProp(
                    position,
                    "Width",
                    0 +
                      extractUnits(
                        borders["border" + capFirstLetter(position) + "Width"]
                      )
                  );
                } else {
                  setValueByProp(
                    position,
                    "Width",
                    newValue +
                      extractUnits(
                        borders["border" + capFirstLetter(position) + "Width"]
                      )
                  );
                }

                // if (ev.target.value == "") {
                //   setValueByProp(
                //     position,
                //     "Width",
                //     0 +
                //       extractUnits(
                //         borders["border" + capFirstLetter(position) + "Width"]
                //       )
                //   );
                // }

                // if (ev.target.value !== "") {
                //   setValueByProp(
                //     position,
                //     "Width",
                //     ev.target.value +
                //       extractUnits(
                //         borders["border" + capFirstLetter(position) + "Width"]
                //       )
                //   );
                // }
              }}
            />
          </div>
          <div
            style={{
              fontSize: "14px",
            }}
            onClick={() => {
              setValueByProp(
                position,
                "Width",
                extractNumbers(
                  borders["border" + capFirstLetter(position) + "Width"]
                ) +
                  1 +
                  extractUnits(
                    borders["border" + capFirstLetter(position) + "Width"]
                  )
              );
            }}
          >
            +
          </div>
        </div>
        <div className="border-menu_input" style={{ position: "relative" }}>
          <Select
            menuPosition="fixed"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={widthUnits.find(
              (x) =>
                x.value ===
                extractUnits(
                  borders["border" + capFirstLetter(position) + "Width"]
                )
            )}
            onChange={(val) => {
              setValueByProp(
                position,
                "Width",
                extractNumbers(
                  borders["border" + capFirstLetter(position) + "Width"]
                ) + val.value
              );
            }}
            options={widthUnits}
            placeholder=""
          ></Select>
        </div>
        {/* <div
          className="color-picker color-picker__swatch-item color-picker__swatch-item__parent position-relative dropup"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        ></div> */}

        <div style={{ position: "relative" }}>
          <div
            onClick={() => {
              setIsColorPickerOpen(!isColorPickerOpen);
              setOpenedColorPicker(position);
            }}
            className="color-picker color-picker__swatch-item position-relative"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            style={{
              backgroundColor:
                borders["border" + capFirstLetter(position) + "Color"],
              display: "flex",

              marginTop: "auto",
              marginBottom: "auto",
            }}
          ></div>

          {isColorPickerOpen && openedColorPicker === position && (
            <ColorPicker
              setColorPickerOpen={(val) => {
                setIsColorPickerOpen(val);
              }}
              open={isColorPickerOpen}
              title="Border"
              align="center"
              top={18}
              value={borders["border" + capFirstLetter(position) + "Color"]}
              onChange={(val, id) => {
                console.log(val, "THIS IS THE VAL", id);

                setValueByProp(position, "Color", val, id ? id : null);
              }}
            />
          )}
        </div>
        <div className="border-menu_input">
          <Select
            menuPosition="fixed"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={borderStyles.find(
              (x) =>
                x.value ===
                borders["border" + capFirstLetter(position) + "Style"]
            )}
            onChange={(val) => {
              setValueByProp(position, "Style", val.value);
            }}
            options={borderStyles}
            placeholder=""
          ></Select>
        </div>
        <div className="d-flex" style={{ alignItems: "center" }}>
          Radius:
        </div>
        <div className="border-menu_input">
          <div
            style={{
              fontSize: "14px",
            }}
            onClick={() => {
              setValueByProp(
                borderPosRadiusMap[position],
                "Radius",
                extractNumbers(
                  borders[
                    "border" +
                      capFirstLetter(borderPosRadiusMap[position]) +
                      "Radius"
                  ]
                ) -
                  1 +
                  extractUnits(
                    borders[
                      "border" +
                        capFirstLetter(borderPosRadiusMap[position]) +
                        "Radius"
                    ]
                  )
              );
            }}
          >
            −
          </div>
          <div>
            <input
              type="text"
              className="global-editor__input builder-input"
              size={1}
              style={{
                width: "25px",
              }}
              value={
                tempBorderValues[`${borderPosRadiusMap[position]}Radius`] !==
                undefined
                  ? tempBorderValues[`${borderPosRadiusMap[position]}Radius`]
                  : extractNumbers(
                      borders[
                        "border" +
                          capFirstLetter(borderPosRadiusMap[position]) +
                          "Radius"
                      ]
                    )
              }
              onChange={(ev) => {
                const newValue = ev.target.value;

                setTempBorderValues((prev) => ({
                  ...prev,
                  [`${borderPosRadiusMap[position]}Radius`]: newValue,
                }));

                if (newValue === "") {
                  setValueByProp(
                    borderPosRadiusMap[position],
                    "Radius",
                    0 +
                      extractUnits(
                        borders[
                          "border" +
                            capFirstLetter(borderPosRadiusMap[position]) +
                            "Radius"
                        ]
                      )
                  );
                } else {
                  setValueByProp(
                    borderPosRadiusMap[position],
                    "Radius",
                    newValue +
                      extractUnits(
                        borders[
                          "border" +
                            capFirstLetter(borderPosRadiusMap[position]) +
                            "Radius"
                        ]
                      )
                  );
                }
              }}
              onBlur={() => {
                setTempBorderValues((prev) => {
                  const updatedValues = { ...prev };
                  delete updatedValues[`${borderPosRadiusMap[position]}Radius`];
                  return updatedValues;
                });
              }}
            />
          </div>
          <div
            style={{
              fontSize: "14px",
            }}
            onClick={() => {
              setValueByProp(
                borderPosRadiusMap[position],
                "Radius",
                extractNumbers(
                  borders[
                    "border" +
                      capFirstLetter(borderPosRadiusMap[position]) +
                      "Radius"
                  ]
                ) +
                  1 +
                  extractUnits(
                    borders[
                      "border" +
                        capFirstLetter(borderPosRadiusMap[position]) +
                        "Radius"
                    ]
                  )
              );
            }}
          >
            +
          </div>
        </div>
        <div className="border-menu_input">
          <Select
            menuPosition="fixed"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={radiusUnits.find(
              (x) =>
                x.value ===
                extractUnits(
                  borders[
                    "border" +
                      capFirstLetter(borderPosRadiusMap[position]) +
                      "Radius"
                  ]
                )
            )}
            onChange={(val) => {
              setValueByProp(
                borderPosRadiusMap[position],
                "Radius",
                extractNumbers(
                  borders[
                    "border" +
                      capFirstLetter(borderPosRadiusMap[position]) +
                      "Radius"
                  ]
                ) + val.value
              );
            }}
            options={radiusUnits}
            placeholder=""
          ></Select>
        </div>
      </>
    );
  }

  return (
    <div className="builder-border-menu builder-border-menu__container">
      <div className="builder-border-menu builder-border-menu__row">
        {renderRow("top")}
        <div className="flex-grow-1 text-end">
          <MoreHorizOutlined
            style={{ height: "16px", width: "19px" }}
            onClick={() => {
              setExpanded(!expanded);
              setTimeout(() => {
                triggerResize();
              }, 300);
            }}
          />
        </div>
      </div>
      {expanded &&
        borderPositions.splice(1).map((pos) => {
          return (
            <div
              className="builder-column builder-border-menu__row"
              key={pos}
              style={{ marginTop: "5px", width: "100%" }}
            >
              {renderRow(pos)}
            </div>
          );
        })}
    </div>
  );
}
