import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext";
import store from "./store/store";
import Redirect from "./components/_default/utilities/Redirect";
// Pages
import Builder from "./pages/Builder";
import axios from "axios";

import "./sass/app.scss";

const urlParams = new URLSearchParams(window.location.search);
const broadcastChannel = new BroadcastChannel("local_storage_channel");

// Helper functions to get and set localStorage with expiry
function getExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

function setExpiry(key, value, ttl) {
  if (value === null || value === undefined) return; // Prevent setting null/undefined values
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
  broadcastChannel.postMessage("localStorageUpdated");
}

// Initialize parameters and localStorage values
let Authorization = getExpiry("Authorization");
let APIKey = getExpiry("APIKey");
let APIID = getExpiry("APIID");
let websiteId = getExpiry("websiteId");
let APIUrl = getExpiry("APIUrl");
let baseURL = getExpiry("baseURL");
let backendURL = getExpiry("backendURL");

if (
  !Authorization ||
  !APIKey ||
  !APIID ||
  !websiteId ||
  !APIUrl ||
  urlParams.get("Authorization") ||
  urlParams.get("APIKey") ||
  urlParams.get("APIID") ||
  urlParams.get("websiteId") ||
  urlParams.get("APIUrl")
) {
  Authorization = urlParams.get("Authorization") || Authorization;
  setExpiry("Authorization", Authorization, 7 * 24 * 60 * 60 * 1000);

  APIKey = urlParams.get("APIKey") || APIKey;
  setExpiry("APIKey", APIKey, 7 * 24 * 60 * 60 * 1000);

  APIID = urlParams.get("APIID") || APIID;
  setExpiry("APIID", APIID, 7 * 24 * 60 * 60 * 1000);

  websiteId = urlParams.get("websiteId") || websiteId;
  setExpiry("websiteId", websiteId, 7 * 24 * 60 * 60 * 1000);

  backendURL = urlParams.get("backendURL") || backendURL;
  setExpiry("backendURL", backendURL, 7 * 24 * 60 * 60 * 1000);

  window.websiteId = websiteId;

  APIUrl = `${backendURL}/builderApi/${websiteId}`;
  setExpiry("APIUrl", APIUrl, 7 * 24 * 60 * 60 * 1000);

  baseURL = `${backendURL}/webstoreApi`;
  setExpiry("baseURL", baseURL, 7 * 24 * 60 * 60 * 1000);

  // Clear URL parameters after storing them
  if (window.history.replaceState) {
    const cleanUrl = window.location.origin + window.location.pathname;
    window.history.replaceState(null, null, cleanUrl);
  }
}

// Set up axios with appropriate headers
var axiosHeaders = {
  "Access-api-key": APIKey,
  "Access-api-id": APIID,
  "Access-Control-Allow-Origin": "*",
};

window.axios = axios.create({
  baseURL: baseURL,
  headers: axiosHeaders,
});

window.API = {
  url: APIUrl,
  backendURL: backendURL,
};

window.defaultApiConfig = {
  mode: "cors",
  headers: {
    Authorization: Authorization,
    "Access-api-key": APIKey,
    "Access-api-id": APIID,
    "Access-Control-Allow-Origin": "*",
  },
};

// Importing Editor component
import Editor from "./components/BuilderComponents/Editors/Editor/Editor";
import { defaultComponents } from "./components/_default/interfaces/DefaultComponents";
defaultComponents.editor = Editor;
window.defaultComponents = defaultComponents;

export default function App() {
  const [expired, setExpired] = useState(false);

  const handleLogin = () => {
    const url = new URL(window.location.href);
    const backendURL = url.searchParams.get("backendURL");
    if (!backendURL) {
      alert("No URL found. Please try again.");
      return;
    }
    window.location.href = backendURL;
  };

  useEffect(() => {
    const checkExpiry = () => {
      console.log("checkexpiry ran");

      if (
        !getExpiry("Authorization") ||
        !getExpiry("APIKey") ||
        !getExpiry("APIID") ||
        !getExpiry("websiteId") ||
        !getExpiry("backendURL")
      ) {
        setExpired(true);
      }
    };

    const handleBroadcast = (message) => {
      if (message.data === "localStorageUpdated" && !expired) {
        checkExpiry();
        if (!expired) {
          window.location.reload();
        }
      }
    };

    checkExpiry();
    broadcastChannel.addEventListener("message", handleBroadcast);
    const interval = setInterval(checkExpiry, 60000); // Check every 60 seconds

    return () => {
      clearInterval(interval);
      broadcastChannel.removeEventListener("message", handleBroadcast);
    };
  }, [expired]);

  // If authorization credentials are missing or expired, show an error page
  if (
    !Authorization ||
    !APIKey ||
    !APIID ||
    !websiteId ||
    !backendURL ||
    expired
  ) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          <b>ERROR 403 - </b> ACCESS DENIED
        </span>
        <span style={{ fontSize: "12px" }}>
          You have no access to this page.
        </span>
        <button
          style={{
            marginTop: "10px",
            backgroundColor: "#777",
            color: "#fff",
            border: "none",
            padding: "5px 10px",
            fontSize: "12px",
            borderRadius: "30px",
            cursor: "pointer",
          }}
          onClick={handleLogin}
        >
          Continue
        </button>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <ShoppingCartProvider>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<Builder title="Builder" />} exact />
          </Routes>
        </BrowserRouter>
      </ShoppingCartProvider>
    </Provider>
  );
}
