import React, { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../../store/hooks";
import { TextComponent as TextComponent_ } from "./interfaces/base";
import styled from "@emotion/styled";
import RenderChildren from "./RenderChildren";
import BackgroundVideoComponent from "./BackgroundVideoComponent";
import useLazyLoad from "./utilities/useLazyLoad";
import { EditorContent } from "@tiptap/react";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

interface Props {
  id: number;
  attributes: TextComponent_["attributes"];
  editor: JSX.Element;
  childElements: any;
  childWebpageComponentRelations: any;
}

export default function TextComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: Props) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);
  const [style, setStyle] = useState<React.CSSProperties>();
  const isBuilder = document.getElementById("BuilderContent");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;

  const [templateEditText, setTemplateEditText] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(
    attributes.styles.backgroundImage ? attributes.styles.backgroundImage : ""
  );

  const builderEditor = useAppSelector((state) => state.builder.editor);

  const textElementRef = useRef<HTMLButtonElement>(null);
  const loaded = useLazyLoad(textElementRef, backgroundImage);
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();
  const [borderColors, setBorderColors] = useState<any>();

  useEffect(() => {
    if (attributes.styles.backgroundImage) {
      setBackgroundImage(attributes.styles.backgroundImage);
    }
  }, [attributes.styles.backgroundImage]);

  let editor = useAppSelector((state) => state.builder?.editor?.editor);

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );

    if (compTypography) {
      setStyle({
        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        fontStyle: compTypography.font_style,
        fontWeight: compTypography.font_weight,
        letterSpacing: compTypography.letter_spacing,
        lineHeight: compTypography.line_height,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
      });
    }
  }, [typographies, attributes]);

  useEffect(() => {
    const backgroundColor = component.attributes?.color_id
      ? colors?.find((x: any) => x.id === component.attributes?.color_id)?.color
      : component.attributes?.styles.backgroundColor
      ? component.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = component.attributes?.hover_color_id
      ? colors?.find((x: any) => x.id === component.attributes?.hover_color_id)
          ?.color
      : component.attributes?.styles[":hover"] &&
        component.attributes?.styles[":hover"].backgroundColor
      ? component.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "none"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    component.attributes,
    component.attributes?.color_id,
    component.attributes?.hover_color_id,
    component.attributes?.styles.backgroundColor,
    component.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  useEffect(() => {
    // Change border color values

    const borderColorValues = {
      borderTopColor: component.attributes?.borderTopColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderTopColorId
          )?.color
        : component.attributes?.styles.borderTopColor
        ? component.attributes?.styles.borderTopColor
        : "",
      borderBottomColor: component.attributes?.borderBottomColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderBottomColorId
          )?.color
        : component.attributes?.styles.borderBottomColor
        ? component.attributes?.styles.borderBottomColor
        : "",
      borderLeftColor: component.attributes?.borderLeftColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderLeftColorId
          )?.color
        : component.attributes?.styles.borderLeftColor
        ? component.attributes?.styles.borderLeftColor
        : "",
      borderRightColor: component.attributes?.borderRightColorId
        ? colors?.find(
            (x: any) => x.id === component.attributes?.borderRightColorId
          )?.color
        : component.attributes?.styles.borderRightColor,
    };

    setBorderColors(borderColorValues);
  }, [
    component.attributes,
    component.attributes.styles,
    component.attributes?.borderBottomColorId,
    component.attributes?.borderLeftColorId,
    component.attributes?.borderRightColorId,
    component.attributes?.borderTopColorId,
    colors,
  ]);

  useEffect(() => {
    const templateContainer = document.getElementById(
      "template_edit_over_lay_content"
    );
    const textElement = textElementRef.current;

    if (
      textElement &&
      templateContainer &&
      templateContainer.contains(textElement)
    ) {
      setTemplateEditText(true);
    }
  }, [editor]);

  const content = (
    <span
      dangerouslySetInnerHTML={{ __html: attributes.text }}
      contentEditable={editorId == id}
      // onMouseUp={(e) => setMouseUp(e)}
      // onKeyDown={(e) => setKeyDown(e)}
      // onBlur={(e) => setOnBlur(e)}
    ></span>
  );

  const tags: { [key: string]: JSX.Element } = {
    h1: (
      <h1
        style={{
          ...style,
          ...attributes.styles,

          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        {content}
      </h1>
    ),
    h2: (
      <h2
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        {content}
      </h2>
    ),
    h3: (
      <h3
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        {content}
      </h3>
    ),
    h4: (
      <h4
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        {content}
      </h4>
    ),
    h5: (
      <h5
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        {content}
      </h5>
    ),
    h6: (
      <h6
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        {content}
      </h6>
    ),
    p: (
      <p
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: loaded
            ? loaded.map((src) => `url("${src}")`).join(", ")
            : "none",
          ...backgroundStyles,
          borderLeftColor: borderColors?.borderLeftColor,
          borderRightColor: borderColors?.borderRightColor,
          borderTopColor: borderColors?.borderTopColor,
          borderBottomColor: borderColors?.borderBottomColor,
        }}
      >
        {content}
      </p>
    ),
  };

  return (
    <Div
      ref={textElementRef}
      id={id.toString()}
      className={
        (attributes.classes ?? "") +
        " builder-component relative builder-component__text-editor" +
        (editorId === id ? " builder-component--active" : "")
      }
      data-style={{
        ...backgroundStyles,
      }}
      spellCheck="false"
      style={{
        position: "relative",
        ...backgroundStyles,
        backgroundColor: backgroundStyles?.backgroundColor
          ? backgroundStyles?.backgroundColor
          : "none",
      }}
    >
      <div style={{ position: "relative", overflow: "hidden" }}>
        <BackgroundVideoComponent id={id} />
      </div>
      {!isBuilder && (
        // <div style={style} dangerouslySetInnerHTML={{ __html: content }}></div>
        <>
          {
            tags[
              typographies?.find((x: any) => x.id === attributes.typography_id)
                ?.tag ?? "p"
            ]
          }
        </>
      )}
      {editorId !== id && isBuilder && (
        // <div style={style} dangerouslySetInnerHTML={{ __html: content }}></div>
        <>
          {
            tags[
              typographies?.find((x: any) => x.id === attributes.typography_id)
                ?.tag ?? "p"
            ]
          }
        </>
      )}
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
      {isBuilder &&
        editorId === id &&
        editor &&
        builderEditor &&
        !builderEditor.template && (
          <>
            <div
              className="builder-component_text-data"
              style={{
                ...style,
                ...attributes.styles,
                backgroundImage: !isBuilder
                  ? loaded
                    ? loaded.map((src) => `url("${src}")`).join(", ")
                    : "none"
                  : attributes.styles.backgroundImage,
                ...backgroundStyles,
              }}
            >
              <EditorContent
                id={id + "_content"}
                content={attributes.text}
                editor={editor}
              ></EditorContent>
            </div>
          </>
        )}

      {isBuilder &&
        editorId === id &&
        editor &&
        templateEditText &&
        builderEditor &&
        builderEditor.template && (
          <>
            <div
              className="builder-component_text-data"
              style={{
                ...style,
                ...attributes.styles,
                backgroundImage: !isBuilder
                  ? loaded
                    ? loaded.map((src) => `url("${src}")`).join(", ")
                    : "none"
                  : attributes.styles.backgroundImage,
                ...backgroundStyles,
              }}
            >
              <EditorContent
                id={id + "_content"}
                content={attributes.text}
                editor={editor}
              ></EditorContent>
            </div>
          </>
        )}
    </Div>
  );
}
