import React, { useState } from "react";
import TemplateApi, {
  Template,
} from "../../../../../store/reducers/builderReducers/templates/templateApi";
import Select from "react-select";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";
import usePageLanguage from "../../../../_default/hooks/usePageLanguage";

interface Props {
  template: Template;
  setGeneralEdit: any;
  setEditTemplate: any;
}

export default function TemplateDetailsGeneralEditForm({
  template,
  setGeneralEdit,
  setEditTemplate,
}: Props) {
  const locale = usePageLanguage();

  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );

  const templateLanguageOptions = languages.map((x) => {
    return {
      label: x.language.name,
      value: x.id,
      locale: x.language.shortname,
    };
  });

  console.log(template, "THIS IS THE OUR TEMPLATE WE EDIT");

  const [cachedTemplateInfo, setCachedTemplateInfo] = useState({
    name: template.name,
    language_id:
      template.language_id ??
      templateLanguageOptions.find((x) => x.locale === locale)?.value,
    use:
      template && template.component && template.component.type === "header"
        ? "header"
        : template.use,
  });

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();

    console.log("cachedTemplateInfo", cachedTemplateInfo);

    if (
      cachedTemplateInfo.language_id === 0 ||
      cachedTemplateInfo.language_id === null
    ) {
      return;
    }

    if (
      cachedTemplateInfo.use === "cookiePolicy" ||
      cachedTemplateInfo.use === "megaMenu" ||
      cachedTemplateInfo.use === "mobileMenu"
    ) {
      TemplateApi.saveTemplateById(template.id, {
        ...template,
        ...cachedTemplateInfo,
        visible: true,
      }).then((res) => {
        setGeneralEdit(false);
        setEditTemplate({ ...template, ...cachedTemplateInfo });
      });

      return;
    } else {
      TemplateApi.saveTemplateById(template.id, {
        ...template,
        ...cachedTemplateInfo,
        visible: true,
      }).then((res) => {
        setGeneralEdit(false);
        setEditTemplate({ ...template, ...cachedTemplateInfo });
      });
      return;
    }
  }

  const DefaultReactSelectStyle_ = {
    ...DefaultReactSelectStyle,
    valueContainer: (styles: any) => {
      return {
        ...styles,
        paddingLeft: "0px",
        paddingRight: "0px",
      };
    },
  };

  const templateUseOptions: any = [
    {
      label: "Default",
      value: "",
      url: "",
    },
    {
      label: "Checkout terms",
      value: "checkoutTerms",
      url: "",
    },

    {
      label: "Cookie Policy",
      value: "cookiePolicy",
      url: "",
    },

    {
      label: "Mega Menu",
      value: "megaMenu",
      url: "",
    },

    {
      label: "Header",
      value: "header",
      url: "",
    },

    {
      label: "Mobile Menu",
      value: "mobileMenu",
      url: "",
    },
  ];

  return (
    <form>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Name</label>
          <input
            className="form-input builder-input"
            value={cachedTemplateInfo.name}
            onChange={(evt) =>
              setCachedTemplateInfo({
                ...cachedTemplateInfo,
                name: evt.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Use</label>
          <Select
            isSearchable={false}
            options={templateUseOptions}
            value={templateUseOptions.find(
              (x: any) => x.value === cachedTemplateInfo.use
            )}
            onChange={(option) => {
              setCachedTemplateInfo({
                ...cachedTemplateInfo,
                use: option!.value,
              });
            }}
            styles={DefaultReactSelectStyle_}
          ></Select>
        </div>
      </div>

      <div className="col-12 b-mt-3">
        <label className="fs-small">Language</label>

        <Select
          isSearchable={false}
          options={templateLanguageOptions}
          value={templateLanguageOptions.find(
            (x: any) => x.value === cachedTemplateInfo.language_id
          )}
          onChange={(option) => {
            setCachedTemplateInfo({
              ...cachedTemplateInfo,
              language_id: option!.value,
            });
          }}
          styles={DefaultReactSelectStyle_}
          required
          noOptionsMessage={() => "FOOOO!"}
          placeholder="Select language"
        ></Select>
      </div>

      <div className="b-my-3">
        <button
          className="sidebar-save-btn"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => setGeneralEdit(false)}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
